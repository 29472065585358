import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Color, Spacing, FontFamilies, FontWeights, BorderRadius } from './Theme';
import { initializeApp } from "firebase/app";
import { getAuth, signInWithCustomToken } from "firebase/auth";

interface HeaderProps {
    height?: string;
    logoSrc: string;
    onLoginSuccess: (token: string) => void;
    onAPISpecSelect: (url: string) => void;
}

const HeaderContainer = styled.header<{ height?: string }>`
    width: 100%;
    height: ${(props) => props.height || '60px'};
    background-color: ${Color.PrimaryDefault};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${Spacing.Space20};
    box-sizing: border-box;
    //position: fixed; // Add this line
    //top: 0; // Add this line
    //z-index: 100; // Add this line
`;

const Logo = styled.img`
    height: 100%;
    max-height: 100%;
`;

const RightSection = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const LoginButton = styled.button<{ loggedIn: boolean }>`
    background-color: ${(props) => (props.loggedIn ? Color.PrimaryHover : Color.White)};
    color: ${(props) => (props.loggedIn ? Color.PrimaryInverse : Color.PrimaryDefault)};
    border: none;
    padding: ${Spacing.Space8} ${Spacing.Space16};
    cursor: pointer;
    font-size: 16px;
    font-family: ${FontFamilies.Body};
    font-weight: ${FontWeights.Bold};
    border-radius: ${BorderRadius.Md};
    transition: background-color 0.3s;

    &:hover {
        background-color: ${(props) => (props.loggedIn ? Color.PrimaryFocus : Color.NeutralsBgDefault)};
    }
`;

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContent = styled.div`
    background-color: ${Color.White};
    padding: ${Spacing.Space32};
    border-radius: ${BorderRadius.Xs};
    width: 300px;
    display: flex;
    flex-direction: column;
    position: relative;
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    color: ${Color.NeutralsCoreDefault};
    font-size: 24px;
    position: absolute;
    top: ${Spacing.Space8};
    right: ${Spacing.Space8};
    cursor: pointer;

    &:hover {
        color: ${Color.NeutralsCoreDisabled};
    }
`;

const Input = styled.input`
    margin-bottom: ${Spacing.Space16};
    padding: ${Spacing.Space8};
    font-size: 16px;
    font-family: ${FontFamilies.Body};
    border: 1px solid ${Color.NeutralsBorderDefault};
    border-radius: ${BorderRadius.Xs};
`;

const SubmitButton = styled.button`
    background-color: ${Color.PrimaryDefault};
    color: ${Color.White};
    border: none;
    padding: ${Spacing.Space8};
    cursor: pointer;
    font-size: 16px;
    font-family: ${FontFamilies.Body};
    font-weight: ${FontWeights.Bold};
    border-radius: ${BorderRadius.Xs};
    transition: background-color 0.3s;

    &:hover {
        background-color: ${Color.PrimaryHover};
    }
`;

const ErrorMessage = styled.div`
    color: ${Color.DangerCoreDefault};
    margin-top: ${Spacing.Space8};
`;

const ApiSpecSelect = styled.select`
    border: none;
    padding: ${Spacing.Space8} ${Spacing.Space16};
    cursor: pointer;
    font-size: 16px;
    font-family: ${FontFamilies.Body};
    font-weight: ${FontWeights.Bold};
    border-radius: ${BorderRadius.Xs};
    transition: background-color 0.3s;
    margin-right: ${Spacing.Space16}; // Add some margin to separate it from the button
`;

const Header: React.FC<HeaderProps> = ({ height, logoSrc, onLoginSuccess, onAPISpecSelect }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);

    const handleLoginClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setError('');
    };

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
        apiKey: "AIzaSyCjwjUIufAibliTXM9XsM3PbvP1xGtgOdI",
        authDomain: "mobble-production.firebaseapp.com",
        databaseURL: "https://mobble-production.firebaseio.com",
        projectId: "mobble-production",
        storageBucket: "mobble-production.appspot.com",
        messagingSenderId: "531016214776",
        appId: "1:531016214776:web:be795c3853116e76",
        measurementId: "G-C6E9REM3ZC"
    };

    // Initialize Firebase
    //const app = initializeApp(firebaseConfig);
    initializeApp(firebaseConfig);

    const auth = getAuth();

    const handleSubmit = async () => {
        try {
            const data = JSON.stringify({
                email,
                password,
                returnSecureToken: true,
                clientType: "CLIENT_TYPE_WEB",
            });

            const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://api.production.aws.mobble.io/auth/sign-in?withClaims=true',
                headers: { 'Content-Type': 'application/json' },
                data: data,
            };

            const response = await axios.request(config);
            //console.log(JSON.stringify(response.data));

            document.querySelectorAll('#http\\.bearer\\.token').forEach((element) => {
                element.innerHTML = response.data.idToken;
            });

            setLoggedIn(true);
            handleCloseModal();


            signInWithCustomToken(auth, response.data)
                .then((userCredential) => {
                    const user = userCredential.user;
                    console.log(user);

                    // Get the ID token
                    user.getIdToken()
                        .then((newToken) => {
                            onLoginSuccess(newToken);
                        })
                        .catch((tokenError) => {
                            console.error('Error getting ID token:', tokenError);
                            setError('Login failed. Please check your credentials and try again.')
                        });

                    // ...
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    setError(`Login failed. Error code: ${errorCode}, Message: ${errorMessage}`);
                    // ...
                });
             // Pass the token to the parent component
        } catch (error) {
            console.error(error);
            setError('Login failed. Please check your credentials and try again.');
        }
    };

    return (
        <>
            <HeaderContainer height={height}>
                <Logo src={logoSrc} alt="Logo" />

                <RightSection>
                    <ApiSpecSelect onChange={(e) => onAPISpecSelect(e.target.value)}>
                        <option value="">Select API</option>
                        <option value="https://map-sensors.apis.mobble.io/v1/docs/openapi.yaml">Map Layer & Sensors API</option>
                    </ApiSpecSelect>

                    <LoginButton onClick={handleLoginClick} loggedIn={loggedIn}>
                        {loggedIn ? 'Authorised' : 'Authorise'}
                    </LoginButton>
                </RightSection>
            </HeaderContainer>
            {isModalOpen && (
                <ModalOverlay onClick={handleCloseModal}>
                    <ModalContent onClick={(e) => e.stopPropagation()}>
                        <CloseButton onClick={handleCloseModal}>&times;</CloseButton>
                        <h2>Login</h2>
                        <Input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <Input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <SubmitButton onClick={handleSubmit}>Log In</SubmitButton>
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                    </ModalContent>
                </ModalOverlay>
            )}
        </>
    );
};

export default Header;
