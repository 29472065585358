export enum Color {
    Black = '#121212', /* Black */
    White = '#ffffff', /* White */
    PrimaryDefault = '#009966',
    PrimaryHover = '#40a86f',
    PrimaryFocus = '#62b779',
    PrimaryInverse = '#ffffff',
    SecondaryDefault = '#3f51b5', /* Indigo */
    SecondaryHover = '#4299e1',
    SecondaryFocus = '#63b3ed',
    SecondaryInverse = '#ffffff',
    BodyBackground = '#ffffff', /* White */
    BodyText = '#121212', /* Black */
    NeutralsBgDefault = '#f1f1f1', /* AlmostWhite */
    NeutralsBgDisabled = '#d8d8d8', /* LightGrey */
    NeutralsCoreDefault = '#606060', /* DarkGrey */
    NeutralsCoreDisabled = '#8e8e93', /* Grey */
    NeutralsBorderDefault = '#606060', /* DarkGrey */
    NeutralsBorderWeak = '#f1f1f1', /* AlmostWhite */
    NeutralsBorderMedium = '#d8d8d8', /* LightGrey */
    NeutralsBorderStrong = '#121212', /* Black */
    DangerBgDefault = '#f4333c', /* Red */
    DangerBgDisabled = '#ee5c5c', /* WashedRed */
    DangerBgInverse = '#ffffff',
    DangerCoreDefault = '#f4333c', /* Red */
    DangerCoreDisabled = '#ee5c5c', /* WashedRed */
    DangerBorderDefault = '#f4333c', /* Red */
    WarningBgDefault = '#ffa000',
    WarningBgDisabled = '#fbd38d',
    WarningBgInverse = '#ffffff',
    WarningCoreDefault = '#ffa000', /* Orange */
    WarningCoreDisabled = '#fbd38d',
    WarningBorderDefault = '#ffa000', /* Orange */
    InfoBgDefault = '#4299e1',
    InfoBgDisabled = '#90cdf4',
    InfoBgInverse = '#ffffff',
    InfoCoreDefault = '#4299e1',
    InfoCoreDisabled = '#90cdf4',
    InfoBorderDefault = '#4299e1',
    SuccessBgDefault = '#40a86f',
    SuccessBgDisabled = '#81c784',
    SuccessBgInverse = '#ffffff',
    SuccessCoreDefault = '#40a86f',
    SuccessCoreDisabled = '#81c784',
    SuccessBorderDefault = '#40a86f',
    Grey100 = '#f1f1f1', /* AlmostWhite */
    Grey200 = '#edf2f7',
    Grey300 = '#d8d8d8', /* LightGrey */
    Grey400 = '#bcbcbc',
    Grey500 = '#8e8e93', /* Grey */
    Grey600 = '#7d7d7d',
    Grey700 = '#606060', /* DarkGrey */
    Grey800 = '#4d4d4d',
    Grey900 = '#3b3b3b',
    Red100 = '#fff5f5',
    Red200 = '#fed7d7',
    Red300 = '#feb2b2',
    Red400 = '#fc8181',
    Red500 = '#ee5c5c', /* WashedRed */
    Red600 = '#f4333c', /* Red */
    Red700 = '#c53030',
    Red800 = '#9b2c2c',
    Red900 = '#742a2a',
    Orange100 = '#fffaf0',
    Orange200 = '#feebc8',
    Orange300 = '#fbd38d',
    Orange400 = '#f6ad55',
    Orange500 = '#ffa000', /* Orange */
    Orange600 = '#f59300',
    Orange700 = '#bf6021',
    Orange800 = '#9c4221',
    Orange900 = '#7b341e',
    Yellow100 = '#fffff0',
    Yellow200 = '#fefcbf',
    Yellow300 = '#faf089',
    Yellow400 = '#f6e05e',
    Yellow500 = '#ffd000', /* Yellow */
    Yellow600 = '#d69e2e',
    Yellow700 = '#b7791f',
    Yellow800 = '#975a16',
    Yellow900 = '#744210',
    Green100 = '#f0fff4',
    Green200 = '#c6f6d5',
    Green300 = '#81c784', /* LightGreen */
    Green400 = '#62b779',
    Green500 = '#40a86f',
    Green600 = '#009966', /* Green */
    Green700 = '#2f855a',
    Green800 = '#276749',
    Green900 = '#22543d',
    Teal100 = '#e6fffa',
    Teal200 = '#b2f5ea',
    Teal300 = '#81e6d9',
    Teal400 = '#4fd1c5',
    Teal500 = '#38b2ac',
    Teal600 = '#319795',
    Teal700 = '#2c7a7b',
    Teal800 = '#285e61',
    Teal900 = '#234e52',
    Blue100 = '#ebf8ff',
    Blue200 = '#bee3f8',
    Blue300 = '#90cdf4',
    Blue400 = '#63b3ed',
    Blue500 = '#4299e1',
    Blue600 = '#2c74f9', /* Blue */
    Blue700 = '#3450e3', /* LightBlue */
    Blue800 = '#3f51b5', /* Indigo */
    Blue900 = '#2d3a82',
    Purple100 = '#faf5ff',
    Purple200 = '#e9d8fd',
    Purple300 = '#d6bcfa',
    Purple400 = '#b794f4',
    Purple500 = '#9575cd', /* Lilac */
    Purple600 = '#6e43c4',
    Purple700 = '#5e35b1', /* Purple */
    Purple800 = '#512d98',
    Purple900 = '#44337a',
    Pink100 = '#fff5f7',
    Pink200 = '#fed7e2',
    Pink300 = '#fbb6ce',
    Pink400 = '#f687b3',
    Pink500 = '#ed64a6',
    Pink600 = '#d53f8c',
    Pink700 = '#b83280',
    Pink800 = '#97266d',
    Pink900 = '#702459',
}

export enum Spacing {
    Space4 = '4px',
    Space8 = '8px',
    Space16 = '16px',
    Space20 = '20px',
    Space24 = '24px',
    Space32 = '32px',
    Space40 = '40px',
    Space48 = '48px',
    Space64 = '64px',
}

export enum Sizing {
    Size4 = '4rem',
    Size8 = '8rem',
    Size12 = '12rem',
    Size14 = '14rem', /* Base size */
    Size16 = '16rem',
    Size20 = '20rem',
    Size24 = '24rem',
    Size28 = '28rem',
    Size32 = '32rem',
    Size40 = '40rem',
    Size48 = '48rem',
    Size56 = '56rem',
    Size64 = '64rem',
}

export enum FontFamilies {
    Heading = 'Ubuntu, system-ui, -apple-system, Segoe UI, Roboto, Noto Sans, Cantarell, Helvetica Neue, sans-serif',
    Body = 'Ubuntu, system-ui, -apple-system, Segoe UI, Roboto, Noto Sans, Cantarell, Helvetica Neue, sans-serif',
    Mono = 'Menlo, Consolas, Monaco, Liberation Mono, monospace',
}

export enum FontWeights {
    Regular = '400',
    Bold = '700',
}

export enum BorderRadius {
    Xs = '4px',
    Sm = '6px',
    Md = '8px',
    Lg = '12px',
    Xl = '16px',
    Full = '50%',
}

export enum BorderWidth {
    Sm = '1px',
    Md = '2px',
    Lg = '3px',
    Xl = '4px',
}

export enum Opacity {
    Disabled = '0.5',
}

export enum Other {
    TimeTransitionFastest = '150',
    TimeTransitionFast = '200',
    TimeTransitionDefault = '300',
    TimeTransitionSlow = '500',
}
